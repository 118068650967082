import { createAsyncThunk } from '@reduxjs/toolkit';
import { CaseModel, RHYME_API_SERVICES } from 'type-declarations';
import { UserState } from '../user';
import Axios from 'axios';
import { get } from 'aws-amplify/api';
import { buildSubmitContentArray, setOliveAuthDataToSessionStorage } from './utils';

const rhymeApiUrl = RHYME_API_SERVICES.RhymeApi;

export const fetchSurvey = createAsyncThunk('survey/fetchSurvey', async (_, { getState }) => {
	const appState: any = getState();
	const { case: caseSlice }: { case: CaseModel } = appState.case;
	const { user: userSlice }: { user: UserState } = appState;

	const surveyRequests = buildSubmitContentArray({
		caseId: caseSlice.caseId,
		submissions: caseSlice.submissions,
		user: userSlice,
	});

	try {
		const apiCalls = await Promise.all(
			surveyRequests.map(async (survey) => {
				const urlParts = survey.url.split('.com/');
				const { body } = await get({
					apiName: rhymeApiUrl,
					path: urlParts[1],
				}).response;
				const data = (await body.json()) as any;

				// Used only survey type "OliveM", captures undefined is OK.
				setOliveAuthDataToSessionStorage(data);

				if (data?.actionEndpoint.includes('priorauthnow.com/mockSurvey.html')) {
					data.actionEndpoint = `
						${window.location.origin}
						/live-auth/assets/surveys/mockSurvey.html?
						${data.actionEndpoint.split('?')[1]}`;
				}

				return {
					response: data,
					umIntegrationUrl: survey.umIntegrationUrl,
					integrationName: survey.integrationName,
				};
			})
		);
		// Return filter response objs with only response property
		return apiCalls.filter((x) => !!x.response) ?? null;
	} catch (error) {
		// eslint-disable-next-line no-console
		console.warn(error);
	}
});

export const fetchOliveManifest = createAsyncThunk('survey/fetchOliveManifest', async () => {
	const oliveMFESurveyPayloadStr = sessionStorage.getItem('OliveMFESurveyPayload');
	let oliveMFESurveyPayload;

	if (oliveMFESurveyPayloadStr) {
		oliveMFESurveyPayload = JSON.parse(oliveMFESurveyPayloadStr);
		const hostUrl = oliveMFESurveyPayload.ManifestJsonUrl;
		const res = await Axios.get(`${hostUrl}/asset-manifest.json`);

		return res;
	}
	return null;
});

export const submitSurvey = createAsyncThunk('survey/submitSurvey', async (_, { getState }) => {
	const appState: any = getState();
	const { user: userSlice }: { user: UserState } = appState;
	const survey = appState.surveys.surveys[0] ?? null;

	if (survey) {
		const config = {
			headers: {
				Authorization: userSlice.idToken,
			},
		};
		const callbackUrl = survey.response.callbackUrl.startsWith('/')
			? survey.response?.callbackUrl.substr(1)
			: survey.response?.callbackUrl;

		// Used to notify Rhyme/UI of survey completion
		const res = await Axios.post(`${survey.umIntegrationUrl}${callbackUrl}`, {}, config);

		return res && res.data ? res.data : null;
	}
});
