import { useAppSelector } from '@app/hooks/useReduxToolkit';
import {
	createEmbeddingContext,
	EmbeddingContext,
	Parameter,
	DashboardExperience,
	EmbeddingEvents,
} from 'amazon-quicksight-embedding-sdk';

import { FC, useEffect, useRef, useState } from 'react';
import { EmbedDashboardUrlResponse, PartnerEntity } from 'type-declarations';
import { getDefaultContentOptions } from 'state-services';

interface Props {
	embedDashboardUrlResponse: EmbedDashboardUrlResponse;
	activeEntity: PartnerEntity;
	onInitialFrameLoaded: () => void;
	initialFrameLoaded: boolean;
}

//A good portion of this component was taken from AWS's documentation on embedding QuickSight
//dashboards using React:
// eslint-disable-next-line max-len
//https://aws.amazon.com/blogs/business-intelligence/level-up-your-react-app-with-amazon-quicksight-how-to-embed-your-dashboard-for-anonymous-access/
const LighthouseEmbedding: FC<Props> = ({
	embedDashboardUrlResponse,
	activeEntity,
	onInitialFrameLoaded,
	initialFrameLoaded,
}) => {
	const dashboardRef = useRef(null);
	const { selectedDashboardId, selectedPartnerEntities, printDashboardIds, resetDashboardIds } =
		useAppSelector((state) => state.lighthouse);
	const [embeddingContext, setEmbeddingContext] = useState(null as null | EmbeddingContext);
	const [embeddedDashboard, setEmbeddedDashboard] = useState(null as null | DashboardExperience);
	const [dashboardFilters, setDashboardFilters] = useState(new Map<string, Parameter[]>());
	const [localActiveDashboardId, setLocalActiveDashboardId] = useState(
		embedDashboardUrlResponse.dashboards[0].dashboardId
	);

	//On initial render only, create the embedding context.
	useEffect(() => {
		createContext();
	}, []);

	const createContext = async (): Promise<void> => {
		const context = await createEmbeddingContext();
		setEmbeddingContext(context);
	};

	//Once the embedding context has been created, we will embed the iFrame onto the DOM.
	useEffect(() => {
		if (embeddingContext) {
			embed();
		}
	}, [embeddingContext]);
	const embed = async (): Promise<void> => {
		if (!embeddingContext) {
			return;
		}
		const frameOptions = {
			url: embedDashboardUrlResponse.embedUrl ?? '',
			container: dashboardRef.current as unknown as HTMLElement,
			height: 'AutoFit',
			resizeHeightOnSizeChangedEvent: true,
			onChange: (changeEvent: EmbeddingEvents): void => {
				switch (changeEvent.eventName) {
					case 'FRAME_LOADED': {
						onInitialFrameLoaded();
						break;
					}
				}
			},
		};
		const contentOptions = getDefaultContentOptions(
			activeEntity,
			selectedPartnerEntities.map((pe) => pe.id)
		);

		try {
			const newDashboardFrame = await embeddingContext.embedDashboard(frameOptions, contentOptions);
			setEmbeddedDashboard(newDashboardFrame);
		} catch {
			//left empty because a common exception occurs where the user navigates away from the Lighthouse tab
			//while the dashboard is mounting. The AWS SDK does not expose a cleanup method to call on unmount,
			//so we're just going to swallow the error keep it from spamming our console/sentry.io logging.
		}
	};

	//If the selected dashboard ID changes and the new ID is in this embedding component's ID list,
	//navigate to the new dashboard.
	useEffect(() => {
		if (
			embeddedDashboard &&
			selectedDashboardId &&
			embedDashboardUrlResponse.dashboards.map((db) => db.dashboardId).includes(selectedDashboardId)
		) {
			navigateToSelectedDashboard(selectedDashboardId);
		}
	}, [selectedDashboardId]);
	const navigateToSelectedDashboard = async (newDashboardId: string): Promise<void> => {
		if (!embeddedDashboard) {
			return;
		}
		//We store the current dashboard's filters so that when the user returns to it
		//they will see all the same filters set.
		const paramsToStore = await embeddedDashboard.getParameters();
		setDashboardFilters(new Map(dashboardFilters.set(localActiveDashboardId, paramsToStore)));
		setLocalActiveDashboardId(newDashboardId);
		let contentOptionsToApply = getDefaultContentOptions(
			activeEntity,
			selectedPartnerEntities.map((pe) => pe.id)
		);
		if (dashboardFilters.has(newDashboardId)) {
			contentOptionsToApply = { parameters: dashboardFilters.get(newDashboardId) };
		}

		await embeddedDashboard.navigateToDashboard(newDashboardId, contentOptionsToApply);
	};

	//When the list of dashboard IDs that have been "printed" changes,
	//check if the most recent dashboard ID is in the Embed object for this
	//embedding. If so, initiate print.
	useEffect(() => {
		if (
			printDashboardIds.length > 0 &&
			embedDashboardUrlResponse.dashboards
				.map((db) => db.dashboardId)
				.includes(printDashboardIds[printDashboardIds.length - 1])
		) {
			embeddedDashboard?.initiatePrint();
		}
	}, [printDashboardIds]);

	//When the list of dashboard IDs that have been "reset" changes,
	//check if the most recent dashboard ID is in the Embed object for this
	//embedding. If so, reset the embedding.
	useEffect(() => {
		if (
			resetDashboardIds.length > 0 &&
			embedDashboardUrlResponse.dashboards
				.map((db) => db.dashboardId)
				.includes(resetDashboardIds[resetDashboardIds.length - 1])
		) {
			embeddedDashboard?.reset();
		}
	}, [resetDashboardIds]);

	/*
		If still awaiting the dashboards to load, return 'none' (iFrame hidden)
		If the selected dashboard ID is found in the parameter embed object's list
		of dashboards, return 'block' because this is the embed object that should be
		visible.

    Otherwise, return 'none'
  */
	const cssDisplayValue = (): 'none' | 'block' => {
		if (!initialFrameLoaded) {
			return 'none';
		}
		const embedObjectIncludesSelectedDashboardId = embedDashboardUrlResponse.dashboards
			.map((db) => db.dashboardId)
			.includes(selectedDashboardId ?? '');
		return embedObjectIncludesSelectedDashboardId ? 'block' : 'none';
	};

	return <div ref={dashboardRef} style={{ display: cssDisplayValue() }} />;
};

export default LighthouseEmbedding;
