export enum DocumentType {
	ClinicalDocument = 'clinicalDocument',
	PayerArtifact = 'payerArtifact',
	ClinicalNote = 'clinicalNote',
}

/**
Members of the DocumentType enum that indicate a file originated from the provider
during a clinical information upload.
*/
export const ClinicalInformationDocumentTypes: Array<DocumentType> = [
	DocumentType.ClinicalDocument,
	DocumentType.ClinicalNote,
];

export enum FileDataStatus {
	PENDING = 'pending',
	UPLOADED = 'uploaded',
	SCANNING = 'scanning',
	INFECTED = 'infected',
	AVAILABLE = 'available',
	UNSUPPORTED = 'unsupported',
	ERROR = 'error',
	SUBMITTED = 'submitted',
	UMDIDNOTLIKETHISFILESOTHEYDIDNOTACCEPTIT = 'umDidNotLikeThisFileSoTheyDidNotAcceptIt',
	CANCELED = 'canceled',
	DELETED = 'deleted',
	WARNING = 'warning',
	// UI Only
	INITIAL = 'initial',
	ZERO_BYTE = 'zero_byte',
	TOO_LARGE = 'too_large',
}

export const TerminalFileDataStatus: Array<FileDataStatus> = [
	FileDataStatus.INFECTED,
	FileDataStatus.SUBMITTED,
	FileDataStatus.ERROR,
	FileDataStatus.UMDIDNOTLIKETHISFILESOTHEYDIDNOTACCEPTIT,
	FileDataStatus.CANCELED,
	FileDataStatus.DELETED,
];

/**
FileDataStatus codes that indicate an issue with a file the user
has selected to upload.
*/
export const LocalErrorFileDataStatus: Array<FileDataStatus> = [
	FileDataStatus.ZERO_BYTE,
	FileDataStatus.TOO_LARGE,
	FileDataStatus.UNSUPPORTED,
];

export interface UploadFileEntry {
	documentName: string;
	documentType: DocumentType;
	file?: File;
}

export interface UploadFileURIRequest {
	files: Array<UploadFileEntry>;
	batchId: string;
}

export interface UploadFileEntryResponse extends UploadFileEntry {
	uri: string;
	fileId: string;
}
export interface UploadFileURIResponseObject {
	files: Array<UploadFileEntryResponse>;
}

export interface FileData {
	fileId: string;
	name: string;
	documentType: DocumentType;
	dateReceived?: string; // ISO Date Please
	status: FileDataStatus;
}

export interface ExtendedFileData extends FileData {
	systemFile?: File;
	clinicalNoteText?: string;
	uri: string;
	//systemFileSize - added for debugging production 0 byte files.
	//When 0 byte files are encountered on AWS, we will be able to see
	//the intended file size on the UI side from Sentry.io
	systemFileSize?: number;
}

export interface NoteResponse {
	clinicalNoteFileId: string;
}
